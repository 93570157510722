<template>
	<div class="animated fadeIn p-3">
		<div class="row">
			<div class="col">
				<b-button
					:variant="!showFilters ? 'primary' : 'secondary'"
					class="btn mx-1 bg-primary-alt"
					@click="showFilters = !showFilters">
					<i
						class="fa fa-filter"
						aria-hidden="true" />
				</b-button>
				<div class="btn btn-lg">
					{{ datePeriod }}
				</div>
			</div>
		</div>
		<hr>
		<data-filter
			:display="showFilters"
			get-by="name"
			@submit="getDataFiltered"
			@clear="clearFilters">
			<template slot="form">
				<div class="row">
					<div
						v-if="admin.includes($user.details().type)"
						class="col-sm">
						<search
							v-model="filters.id"
							:label-text="translate('autocomplete_label')"
							:endpoint="endpoint"
							:empty-result-message="translate('data_not_found')"
							:placeholder="'autocomplete_placeholder'"
							:default-params="{role:'distributor'}"
							class="mb-3"
							q="label"
							return-key="id" />
					</div>
					<div class="col-sm">
						<div
							class="form-group">
							<label
								for="dateRange"
								class="label">{{ translate('date_range') }}</label>
							<select
								id="dateRange"
								v-model="dateRange"
								name="dateRange"
								class="form-control">
								<option
									v-for="dateRange in dateRanges"
									:key="dateRange"
									:value="dateRange">
									{{ translate(dateRange) }}
								</option>
							</select>
						</div>
					</div>
					<div
						v-if="dateRange === dateRanges.custom"
						class="col-sm">
						<div
							class="form-group">
							<label
								for="startDate"
								class="label">{{ translate('start_date') }}</label>
							<v-date-picker
								id="startDate"
								v-model="startDate"
								:input-props="{ class: 'form-control' }"
								:formats="calendarConfig"
								name="startDate"
								show-caps />
						</div>
					</div>
					<div
						v-if="dateRange === dateRanges.custom"
						class="col-sm">
						<div
							class="form-group">
							<label
								for="endDate"
								class="label">{{ translate('end_date') }}</label>
							<v-date-picker
								id="endate"
								v-model="endDate"
								:input-props="{ class: 'form-control' }"
								:formats="calendarConfig"
								name="endDate"
								show-caps />
						</div>
					</div>
				</div>
			</template>
		</data-filter>
		<div class="table-responsive">
			<table class="table table-hover table-striped text-nowrap">
				<thead>
					<tr>
						<th>
							#
						</th>
						<th>
							{{ translate('user_id') }}
						</th>
						<th>
							{{ translate('username') }}
						</th>
						<th
							v-if="isAdminOrCorporate"
							class="text-center">
							{{ translate('country') }}
						</th>
						<!-- <th>
							{{ translate('name') }}
						</th> -->
						<th
							class="text-right pointer"
							@click="sortPbvTopByField('personal_orders')">
							{{ translate('personal_orders') }}
							<sort
								:import-data="sortData"
								field="personal_orders" />
						</th>
						<th
							class="text-right pointer"
							@click="sortPbvTopByField('sponsored_orders')">
							{{ translate('sponsored_orders') }}
							<sort
								:import-data="sortData"
								field="sponsored_orders" />
						</th>
						<th
							class="text-right pointer"
							@click="sortPbvTopByField('guest_orders')">
							{{ translate('customer_orders') }}
							<sort
								:import-data="sortData"
								field="guest_orders" />
						</th>
						<th
							class="text-right pointer"
							@click="sortPbvTopByField('total')">
							{{ translate('total') }}
							<sort
								:import-data="sortData"
								field="total" />
						</th>
					</tr>
				</thead>
				<tbody v-if="!loading && hasData">
					<tr
						v-for="(item, index) in data"
						:key="index"
						class="text-nowrap">
						<td class="align-middle">
							{{ index + 1 }}
						</td>
						<td class="align-middle">
							<template v-if="admin.includes($user.details().type)">
								<span
									class="pointer badge badge-primary"
									@click="toDashboard({ distributorId: item.attributes.user_id.toString() })">{{ item.attributes.user_id }}</span>
							</template>
							<template v-else>
								<span class="badge badge-primary">{{ item.attributes.user_id }}</span>
							</template>
						</td>
						<td class="align-middle">
							{{ item.attributes.username }}
						</td>
						<td
							v-if="isAdminOrCorporate"
							class="text-center">
							<img
								v-b-tooltip.hover
								:title="translate(item.attributes.country_code.toLowerCase())"
								:src="getFlag(item.attributes.country_code, true)"
								:alt="translate(item.attributes.country_code.toLowerCase())">
						</td>
						<!-- <td class="align-middle">
							{{ item.attributes.user_full_name }}
						</td> -->
						<td class="align-middle text-right">
							{{ item.attributes.personal_orders }}
						</td>
						<td class="align-middle text-right">
							{{ item.attributes.sponsored_orders }}
						</td>
						<td class="align-middle text-right">
							{{ item.attributes.customer_orders }}
						</td>
						<td class="align-middle text-right">
							{{ item.attributes.total_bvs }}
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<is-loading
			:loading-label="translate('loading')"
			:no-data-label="translate('data_not_found')"
			:loading="loading"
			:has-data="hasData" />
	</div>
</template>
<script>
import {
	PersonalBusinessVolume as PBV, Grids, Countries,
} from '@/translations';
import FiltersParams from '@/mixins/FiltersParams';
import DashboardRedirect from '@/mixins/DashboardRedirect';
import {
	PERIOD_FILTERS as dateRanges,
	V_CALENDAR_CONFIG as calendarConfig,
	DATE_START_FILTER as dateStartFilter,
	DATE_END_FILTER as dateEndFilter,
	MDY_FORMAT as mdyFormat,
	custom,
	allTime,
} from '@/settings/Dates';
import { admin } from '@/settings/Roles';
import {
	DESC_ORDER as desc,
	ORDER_FIELD_NAME as field,
	ORDER_DIR_NAME as dir,
} from '@/settings/RequestReply';
import DataFilter from '@/components/DataFilter';
import Sort from '@/components/SortArrow';
import PersonalBusinessVolume from '@/util/Report';
import { SEARCH_USERS } from '@/config/endpoint';
import WindowSizes from '@/mixins/WindowSizes';

const { endpoint } = SEARCH_USERS;
export default {
	name: 'PersonalBusinessVolumeTop',
	messages: [PBV, Grids, Countries],
	components: {
		DataFilter,
		Sort,
	},
	mixins: [FiltersParams, DashboardRedirect, WindowSizes],
	data() {
		return {
			dateRanges,
			calendarConfig,
			mdyFormat,
			custom,
			allTime,
			endpoint,
			rangeStart: '',
			rangeEnd: '',
			admin,
			sortData: {},
			personalBusinessVolume: new PersonalBusinessVolume(),
		};
	},
	computed: {
		loading() {
			return !!this.personalBusinessVolume.data.loading;
		},
		data() {
			try {
				const { data } = this.personalBusinessVolume.data.response.data;
				return data;
			} catch (error) {
				return [];
			}
		},
		hasData() {
			const response = this.data.length;
			return !!response;
		},
		datePeriod() {
			return this.getDatePeriod();
		},
		isAdminOrCorporate() {
			return this.admin.includes(this.$user.details().type);
		},
	},
	mounted() {
		let { id } = this.$user.details();
		if (this.filters.id) {
			id = this.filters.id;
		}
		this.personalBusinessVolume.getTopPBV(id).then(() => {
			const { start, end } = this.personalBusinessVolume.data.response.data.meta.period_dates;
			this.rangeStart = this.$moment(start.date).format(mdyFormat);
			this.rangeEnd = this.$moment(end.date).format(mdyFormat);
		});
		this.datesConfig();
	},
	methods: {
		datesConfig() {
			this.rangeStart = '';
			this.rangeEnd = '';
			delete this.dateRanges.all_time;
			delete this.dateRanges.custom;
			this.dateRanges.all_time = this.allTime;
			this.dateRanges.custom = this.custom;
			const { query } = this.$route;
			const dateKeys = Object.keys(query);
			if (typeof this.dateRanges[query.dateRange] !== 'undefined') {
				this.dateRange = query.dateRange;
			} else if (typeof query.dateRange !== 'undefined' && !Object.values(this.dateRanges).includes(query.dateRange)) {
				this.dateRange = this.dateRanges.this_month;
				query.dateRange = this.dateRanges.this_month;
			} else if (dateKeys.includes(dateStartFilter) || dateKeys.includes(dateEndFilter)) {
				this.dateRange = this.dateRanges.custom;
			} else if (typeof query.dateRange === 'undefined') {
				this.dateRange = this.dateRanges.this_month;
			}
			return null;
		},
		getDataFiltered() {
			const { query } = this.$route;
			const options = { ...query, ...this.filters };
			let { id } = this.$user.details();
			if (this.filters.id) {
				id = this.filters.id;
			}
			this.personalBusinessVolume.getTopPBV(id, options);
		},
		getDatePeriod() {
			const start = this.rangeStart;
			const end = this.rangeEnd;
			if (start !== '' && end !== '') {
				return this.translate('date_period', { start, end });
			}

			if (start !== '') {
				return this.translate('date_period_from', { start });
			}

			if (end !== '') {
				return this.translate('date_period_until', { end });
			}
			return '';
		},
		sortPbvTopByField(fieldName) {
			const { query } = this.$route;

			// assign the sort field (i.e. username)
			query[field] = fieldName;
			// assign order direction
			query[dir] = desc;
			this.sortData = { ...query };
			this.$router.replace('?');
			this.$router.replace({ query });
		},
		getFlag(src, isSmall) {
			let path = 'header';
			if (isSmall === true) {
				path = 'select';
			}
			try {
				/* eslint-disable global-require */
				/* eslint-disable import/no-dynamic-require */
				return require(`@/assets/images/common/countries/${path}/${src}.png`);
			} catch (error) {
				return require(`@/assets/images/common/countries/${path}/default.png`);
			}
		},
	},
};
</script>
